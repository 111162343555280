import React from "react";
import Chart, { ChartData, ChartOptions } from "./Chart";
import Card from "components/Cards/Card";

type Props = {
  width?: string | number;
  height?: string | number;
  className?: string;
};

export default function CardLineChart({
  width,
  height,
  className = "bg-backgroud-300 text-foreground-300",
}: Props) {
  const data: ChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: new Date().getFullYear().toString(),
        backgroundColor: "#4c51bf",
        borderColor: "#4c51bf",
        data: [65, 78, 66, 44, 56, 67, 75],
        fill: false,
      },
      {
        label: (new Date().getFullYear() - 1).toString(),
        fill: false,
        backgroundColor: "#fff",
        borderColor: "#fff",
        data: [40, 68, 86, 74, 56, 60, 87],
      },
    ],
  };
  const options: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Orders Chart",
      },
      legend: {
        align: "end",
        position: "bottom",
      },
    },
    /*animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0.6,
        loop: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
      },
    },*/
  };

  return (
    <Card className={className}>
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className={`uppercase mb-1 text-xs font-semibold`}>Overview</h6>
            <h2 className={`text-xl font-semibold`}>Sales value</h2>
          </div>
        </div>
      </div>
      <div className="p-4 flex-auto">
        <div className="relative">
          <Chart
            id="line-chart"
            type="line"
            data={data}
            options={options}
            width={width}
            height={height}
          />
        </div>
      </div>
    </Card>
  );
}
