import React, { ReactNode, useState } from "react";

// components
import Footer from "components/Footers/Footer";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminSidebar from "components/Sidebars/AdminSidebar";
import SEO from "components/Common/SEO";
import IconGoBack from "components/Common/IconGoBack";
import IconGoTop from "components/Common/IconGoTop";

type Props = {
  title?: string;
  path?: string;
  description?: string;
  backButton?: boolean;
  children: ReactNode;
};

export default function Layout({
  title,
  path,
  description,
  backButton = false,
  children,
}: Props) {
  const [sidebarShow, setSidebar] = useState(false);
  const onToggleMenu = () => {
    setSidebar(!sidebarShow);
  };
  const onCloseMenu = () => {
    setSidebar(false);
  };

  return (
    <>
      <SEO title={title} path={path} description={description} />
      <AdminNavbar onToggleMenu={onToggleMenu} />
      <AdminSidebar
        className="w-64 md:w-52 lg:w-64"
        menuOpen={sidebarShow}
        onCloseMenu={onCloseMenu}
      />
      <div
        className={`md:ml-52 lg:ml-64 pt-12 bg-background-100 text-foreground-200 ${
          sidebarShow ? "h-screen overflow-hidden" : ""
        }`}
      >
        <div className="pb-10 min-h-screen-50">{children}</div>
        <IconGoBack show={backButton} />
        <IconGoTop />
        <Footer />
      </div>
    </>
  );
}
