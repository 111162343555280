import React from "react";
import { PageProps } from "gatsby";
import { Router } from "@reach/router";
import AdminLayout from "layouts/AdminLayout";
import PrivateRoute from "components/Routes/PrivateRoute";
import Route from "components/Routes/Route";
import NewsPage from "templates/news";
import CompanyPage from "pages/company";
import Dashboard from "components/Admin/Dashboard";
import Profile from "components/User/Profile";

export default function Page(props: PageProps) {
  return (
    <AdminLayout>
      <Router basepath="/admin">
        <Route {...props} path="/news" component={NewsPage} />
        <Route {...props} path="/company" component={CompanyPage} />
        <PrivateRoute {...props} path="/" component={Dashboard} />
        <PrivateRoute {...props} path="/dashboard" component={Dashboard} />
        <PrivateRoute {...props} path="/profile" component={Profile} />
      </Router>
    </AdminLayout>
  );
}
