import React, { MouseEventHandler } from "react";

import Link from "components/Common/Link";
import Config from "config/admin/menu";
import { handleLogout } from "services/auth";

type Props = {
  className?: string;
  menuOpen: boolean;
  onCloseMenu: MouseEventHandler;
};

export default function Sidebar({
  className = "",
  menuOpen,
  onCloseMenu,
}: Props) {
  const MenuContent = (
    <nav>
      {Config.sidebar.map((link, i) => {
        return (
          <Link
            key={i}
            className="flex items-center px-4 py-2 text-foreground-200 rounded-md hover:text-primary-400"
            href={link.href}
            icon={link.icon}
          >
            {link.title}
          </Link>
        );
      })}
      <Link
        className="flex items-center px-4 py-2 text-foreground-200 rounded-md hover:text-primary-400"
        href="/auth/logout"
        icon="user-cog"
        onClick={handleLogout}
      >
        Logout
      </Link>
    </nav>
  );

  return (
    <>
      <div
        className={`z-40 hidden md:flex fixed lg:flex-col h-screen px-4 py-8 bg-background-200 text-background-300 border-r border-background-300 ${className}`}
      >
        <div className="z-50 flex flex-col justify-between flex-1 pt-10 overflow-y-scroll">
          <nav>{MenuContent}</nav>
        </div>
      </div>

      <div
        className={`z-40 md:hidden absolute w-screen h-screen ${
          menuOpen ? "" : "hidden"
        }`}
      >
        <div
          className={`z-30 w-full h-full absolute bg-background transition-opacity duration-700 ease-in-out ${
            menuOpen ? "opacity-75" : "opacity-0"
          }`}
          onClick={onCloseMenu}
        ></div>
        <div
          className={`z-40 pt-16 absolute bg-background-200 overflow-y-scroll h-full flex flex-col transition transform duration-500 ease-in-out ${className} ${
            menuOpen ? "translate-x-0 opacity-100" : "-translate-x-64 opacity-0"
          }`}
        >
          <nav>{MenuContent}</nav>
        </div>
      </div>
    </>
  );
}
