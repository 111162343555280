import React from "react";

import Card from "components/Cards/Card";
import ServiceChart from "./ServiceChart";
import { ChartType } from "./Chart";

type Props = {
  id: string;
  chartId: string;
  type: ChartType;
  showName?: boolean;
  className?: string;
  subtitle?: string;
  width?: string | number;
  height?: string | number;
  params?: { [key: string]: string };
};

export default function CardServiceChart({
  id,
  chartId,
  className,
  ...rest
}: Props) {
  return (
    <Card className={`overflow-hidden h-full ${className}`}>
      <ServiceChart id={id} chartId={chartId} {...rest} />
    </Card>
  );
}
