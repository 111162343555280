export default {
  sidebar: [
    {
      href: "/admin/",
      title: "Dashboard",
      icon: "home",
    },
    {
      href: "/admin/",
      title: "Account",
      icon: "user",
    },
    {
      href: "/admin/",
      title: "Settings",
      icon: "user-cog",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
    {
      href: "/admin/",
      title: "Logout",
      icon: "sign-out-alt",
    },
  ],
};
