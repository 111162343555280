import React from "react";
import CardBarChart from "components/Charts/CardBarChart";
import CardLineChart from "components/Charts/CardLineChart";
import CardServiceChart from "components/Charts/CardServiceChart";

export default function Dashboard() {
  return (
    <>
      <div className="h-full w-full pb-32">
        <div className="flex flex-wrap h-full w-full">
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardBarChart className="bg-background-200 text-foreground-300" />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardLineChart className="bg-background-200 text-foreground-300" />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardBarChart className="bg-background-200 text-foreground-300" />
          </div>
        </div>

        <div className="flex flex-wrap h-full w-full">
          <div className="px-2 pt-4 w-full">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart1"
              chartId="comments-count"
              type="line"
            />
          </div>
          <div className="px-2 pt-4 w-full">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart2"
              chartId="news-count"
              type="line"
            />
          </div>
        </div>

        <div className="flex flex-wrap h-full w-full">
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart3"
              chartId="publisher-percent"
              type="doughnut"
              height={200}
            />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart4"
              chartId="publisher-percent"
              type="pie"
              height={200}
            />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart5"
              chartId="news-count"
              type="line"
              height={230}
            />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart6"
              chartId="yahoo-publishers"
              type="line"
            />
          </div>
          <div className="px-2 pt-4 h-350-px md:w-6/12 lg:w-4/12">
            <CardServiceChart
              className="bg-background-200 text-foreground-300"
              id="chart7"
              chartId="yahoo-publishers"
              type="pie"
            />
          </div>
        </div>
      </div>
    </>
  );
}
